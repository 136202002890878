import Loading from 'components/Loading'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import Select from 'new-components/Select'
import {
  GENDER_IDENTITY_LIST,
  NATIONALITY_LIST
} from 'pages/Registration/components/Forms/components/RegistrationForm/constants'
import { getFieldError } from 'pages/Registration/components/Forms/helpers'
import { MdInfoOutline } from 'react-icons/md'
import { dateMask, formatCpf } from 'utils/formatters'
import usePersonalData from './hooks/usePersonalData'
import { PhoneInput } from 'react-international-phone'
import * as Styles from './styles'
import 'react-international-phone/style.css'

function PersonalData() {
  const {
    name,
    nationality,
    preferredName,
    setPreferredName,
    genderIdentity,
    cpf,
    rg,
    rne,
    birthdate,
    telephone,
    setTelephone,
    cellphone,
    email,
    changeFieldValue,
    errors,
    onRegistrationFormSubmit,
    signedUser,
    loading
  } = usePersonalData()

  return (
    <Styles.PersonalDataContainer onSubmit={onRegistrationFormSubmit}>
      <Loading loading={loading} />

      <Styles.InputsContainer>
        <Styles.VariableWidthContainer width="67%">
          <Input
            id="name"
            label="Nome completo*"
            placeholder="Digite seu nome completo"
            value={name}
            errorMessage={getFieldError('name', errors)}
            onChange={(event) => changeFieldValue('name', event.target.value)}
            disabled
          />
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="33%">
          <Select
            id="nationality"
            label="Nacionalidade*"
            options={NATIONALITY_LIST}
            value={nationality}
            errorMessage={getFieldError('nationality', errors)}
            onChange={(newValue) => changeFieldValue('nationality', newValue)}
          />
        </Styles.VariableWidthContainer>
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        <Input
          id="preferred-name"
          label="Nome de preferência"
          placeholder="Escolha como prefere ser chamado"
          value={preferredName}
          onChange={(event) => setPreferredName(event.target.value)}
        />
        <Select
          id="gender-identity"
          label="Identidade de gênero*"
          options={GENDER_IDENTITY_LIST}
          value={genderIdentity}
          errorMessage={getFieldError('genderIdentity', errors)}
          onChange={(newValue) => changeFieldValue('genderIdentity', newValue)}
        />
        <Input
          id="cpf"
          label="CPF*"
          placeholder="Digite seu CPF"
          value={cpf}
          errorMessage={getFieldError('cpf', errors)}
          onChange={(event) => changeFieldValue('cpf', formatCpf(event.target.value))}
          disabled
        />
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {nationality === 'estrangeira' ? (
          <Input
            id="rne"
            label="RNE*"
            placeholder="Digite seu RNE"
            value={rne}
            errorMessage={getFieldError('rne', errors)}
            onChange={(event) => changeFieldValue('rne', event.target.value)}
          />
        ) : (
          <Input
            id="rg"
            label="RG*"
            placeholder="Digite seu RG"
            value={rg}
            errorMessage={getFieldError('rg', errors)}
            onChange={(event) => changeFieldValue('rg', event.target.value)}
          />
        )}
        <Input
          id="birthdate"
          label="Data de nascimento*"
          placeholder="dd/mm/aaaa"
          value={birthdate}
          errorMessage={getFieldError('birthdate', errors)}
          onChange={(event) => changeFieldValue('birthdate', dateMask(event.target.value))}
          disabled
        />
        {(signedUser.registration_type === 1 ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM') && (
          <div
            style={{
              gap: '12px',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <span style={{ fontSize: '1.5rem' }}>Telefone</span>
            <PhoneInput
              defaultCountry="br"
              value={telephone}
              onChange={(phone) => {
                setTelephone(phone.replace(/\D/g, ''))
              }}
              inputStyle={{
                border: '1px solid #d9d9d9',
                borderRadius: '0.5rem',
                width: 'fit-content',
                height: '3.7rem',
                padding: '1rem'
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.5rem',
                  height: '3.7rem',
                  padding: '1rem'
                }
              }}
              inputProps={{ id: 'telephone' }}
              forceDialCode
            />
          </div>
        )}
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {signedUser.registration_type === 2 ? (
          <>
            {signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
            signedUser.prescriber?.council.toUpperCase() === 'CRM' ? (
              <Styles.VariableWidthContainer width="67%">
                <Input
                  id="email"
                  label="E-mail*"
                  placeholder="Digite seu e-mail"
                  value={email}
                  errorMessage={getFieldError('email', errors)}
                  onChange={(event) => changeFieldValue('email', event.target.value)}
                  disabled
                />
              </Styles.VariableWidthContainer>
            ) : (
              <div
                style={{
                  gap: '12px',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <span style={{ fontSize: '1.5rem' }}>Telefone</span>
                <PhoneInput
                  defaultCountry="br"
                  value={telephone}
                  onChange={(phone) => {
                    setTelephone(phone.replace(/\D/g, ''))
                  }}
                  inputStyle={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '0.5rem',
                    width: 'fit-content',
                    height: '3.7rem',
                    padding: '1rem'
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      border: '1px solid #d9d9d9',
                      borderRadius: '0.5rem',
                      height: '3.7rem',
                      padding: '1rem'
                    }
                  }}
                  inputProps={{ id: 'telephone' }}
                  forceDialCode
                />
              </div>
            )}
            <Styles.VariableWidthContainer
              width={
                signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
                signedUser.prescriber?.council.toUpperCase() === 'CRM'
                  ? '33%'
                  : '100%'
              }>
              <div
                style={{
                  gap: '12px',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <span style={{ fontSize: '1.5rem' }}>Celular/WhatsApp*</span>
                <PhoneInput
                  defaultCountry="br"
                  value={cellphone}
                  onChange={(phone) => {
                    changeFieldValue('cellphone', phone.replace(/\D/g, ''))
                  }}
                  inputStyle={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '0.5rem',
                    width: 'fit-content',
                    height: '3.7rem',
                    padding: '1rem'
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      border: '1px solid #d9d9d9',
                      borderRadius: '0.5rem',
                      height: '3.7rem',
                      padding: '1rem'
                    }
                  }}
                  inputProps={{ id: 'cellphone' }}
                  forceDialCode
                />
              </div>
            </Styles.VariableWidthContainer>
          </>
        ) : (
          <>
            <div
              style={{
                gap: '12px',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <span style={{ fontSize: '1.5rem' }}>Celular/WhatsApp*</span>
              <PhoneInput
                defaultCountry="br"
                value={cellphone}
                onChange={(phone) => {
                  changeFieldValue('cellphone', phone.replace(/\D/g, ''))
                }}
                inputStyle={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.5rem',
                  width: 'fit-content',
                  height: '3.7rem',
                  padding: '1rem'
                }}
                countrySelectorStyleProps={{
                  buttonStyle: {
                    border: '1px solid #d9d9d9',
                    borderRadius: '0.5rem',
                    height: '3.7rem',
                    padding: '1rem'
                  }
                }}
                inputProps={{ id: 'cellphone' }}
                forceDialCode
              />
            </div>
            <Styles.VariableWidthContainer width="200%">
              <Input
                id="email"
                label="E-mail*"
                placeholder="Digite seu e-mail"
                value={email}
                errorMessage={getFieldError('email', errors)}
                onChange={(event) => changeFieldValue('email', event.target.value)}
                disabled
              />
            </Styles.VariableWidthContainer>
          </>
        )}
      </Styles.InputsContainer>
      <Styles.VariableWidthContainer width="100%">
        <Input
          id="email"
          label="E-mail*"
          placeholder="Digite seu e-mail"
          value={email}
          errorMessage={getFieldError('email', errors)}
          onChange={(event) => changeFieldValue('email', event.target.value)}
          disabled
        />
      </Styles.VariableWidthContainer>
      <Styles.ConfirmChangesContainer>
        <Styles.ChangeSensibleDataContainer>
          <MdInfoOutline />
          <p>
            Se deseja alterar CPF, nome, data de nascimento ou email, por favor entre em contato com o{' '}
            <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank" rel="noreferrer">
              atendimento Cannect
            </a>
            .
          </p>
        </Styles.ChangeSensibleDataContainer>
        <Button type="submit">ATUALIZAR MEUS DADOS</Button>
      </Styles.ConfirmChangesContainer>
    </Styles.PersonalDataContainer>
  )
}

export default PersonalData
