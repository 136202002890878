/* eslint-disable no-nested-ternary */
import Select from 'new-components/Select'
import Input from 'new-components/Input'
import { formatCpf, dateMask } from 'utils/formatters'
import useRegistration, { RegistrationPath } from 'pages/Registration/hooks/useRegistration'
import CheckboxInput from 'new-components/CheckboxInput'
import { Link } from 'react-router-dom'
import { MdInfoOutline } from 'react-icons/md'
import { Tooltip } from 'antd'
import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import Loading from 'components/Loading'
import * as SharedStyles from '../../../styles'
import * as Styles from './styles'
import { GENDER_IDENTITY_LIST, LIST_AS_PRESCRIBER_POSSIBILITIES, NATIONALITY_LIST } from './constants'
import { getFieldError } from '../../helpers'
import useRegistrationForm from '../../hooks/useRegistrationForm'
import { Button } from '@cannect/new-components/atoms'
import { useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

interface RegistrationFormProps {
  onGoBack: () => void
  path: RegistrationPath
}

export default function RegistrationForm({ onGoBack, path }: RegistrationFormProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const isProfessional = path === 'professional'
  const registrationFields = useRegistration()
  const {
    typeOfBoard,
    loading,
    name,
    nationality,
    preferredName,
    setPreferredName,
    genderIdentity,
    cpf,
    rg,
    rne,
    birthdate,
    setTelephone,
    email,
    emailConfirmation,
    password,
    passwordConfirmation,
    listAsPrescriber,
    changeFieldValue,
    errors
  } = registrationFields

  const {
    onRegistrationFormSubmit,
    changeAgreedToUseTerms,
    setReceivePromotionalEmails,
    agreedToUseTerms,
    receivePromotionalEmails
  } = useRegistrationForm(registrationFields)

  const [phone, setPhone] = useState('')
  const [telphone, setTelphone] = useState('')

  return (
    <Styles.RegistrationFormContainer onSubmit={onRegistrationFormSubmit}>
      <Loading loading={loading} />
      <SharedStyles.InputsContainer>
        <Styles.VariableWidthContainer width="67%">
          <Input
            id="name"
            label="Nome completo*"
            placeholder="Digite seu nome completo"
            value={name}
            errorMessage={getFieldError('name', errors)}
            onChange={(event) => changeFieldValue('name', event.target.value)}
          />
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="33%">
          <Input
            id="cpf"
            label="CPF*"
            placeholder="Digite seu CPF"
            value={cpf}
            errorMessage={getFieldError('cpf', errors)}
            onChange={(event) => changeFieldValue('cpf', formatCpf(event.target.value))}
          />
        </Styles.VariableWidthContainer>
      </SharedStyles.InputsContainer>

      {isProfessional && (
        <SharedStyles.InputsContainer>
          <Input
            id="preferred-name"
            label="Nome de preferência"
            placeholder="Escolha como prefere ser chamado"
            value={preferredName}
            onChange={(event) => setPreferredName(event.target.value)}
          />
          <Select
            id="gender-identity"
            label="Identidade de gênero*"
            options={GENDER_IDENTITY_LIST}
            value={genderIdentity}
            errorMessage={getFieldError('genderIdentity', errors)}
            onChange={(newValue) => changeFieldValue('genderIdentity', newValue)}
          />
          <Select
            id="nationality"
            label="Nacionalidade*"
            options={NATIONALITY_LIST}
            value={nationality}
            errorMessage={getFieldError('nationality', errors)}
            onChange={(newValue) => changeFieldValue('nationality', newValue)}
          />
        </SharedStyles.InputsContainer>
      )}

      {isProfessional && (
        <SharedStyles.InputsContainer>
          {nationality === 'estrangeira' ? (
            <Input
              id="rne"
              label="RNE*"
              placeholder="Digite seu RNE"
              value={rne}
              errorMessage={getFieldError('rne', errors)}
              onChange={(event) => changeFieldValue('rne', event.target.value)}
            />
          ) : (
            <Input
              id="rg"
              label="RG*"
              placeholder="Digite seu RG"
              value={rg}
              errorMessage={getFieldError('rg', errors)}
              onChange={(event) => changeFieldValue('rg', event.target.value)}
            />
          )}
          {typeOfBoard === 'CRM' && (
            <div
              style={{
                gap: '12px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}>
              <span className="font-medium" style={{ fontSize: '1.5rem' }}>
                Telefone
              </span>
              <PhoneInput
                defaultCountry="br"
                value={telphone}
                onChange={(phone) => {
                  setTelphone(phone.replace(/\D/g, ''))
                  setTelephone(phone.replace(/\D/g, ''))
                }}
                inputStyle={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.5rem',
                  width: '100%',
                  height: '3.7rem',
                  padding: '1rem'
                }}
                countrySelectorStyleProps={{
                  buttonStyle: {
                    border: '1px solid #d9d9d9',
                    borderRadius: '0.5rem',
                    height: '3.7rem',
                    padding: '1rem'
                  }
                }}
                inputProps={{ id: 'telephone' }}
                forceDialCode
              />
            </div>
          )}
        </SharedStyles.InputsContainer>
      )}

      <SharedStyles.InputsContainer>
        <Input
          id="birthdate"
          label="Data de nascimento*"
          placeholder="dd/mm/aaaa"
          value={birthdate}
          errorMessage={getFieldError('birthdate', errors)}
          onChange={(event) => changeFieldValue('birthdate', dateMask(event.target.value))}
        />
        <div
          style={{
            gap: '12px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}>
          <span className="font-medium" style={{ fontSize: '1.5rem' }}>
            Celular/WhatsApp*
          </span>
          <PhoneInput
            defaultCountry="br"
            value={phone}
            onChange={(phone) => {
              setPhone(phone.replace(/\D/g, ''))
              changeFieldValue('cellphone', phone.replace(/\D/g, ''))
            }}
            inputStyle={{
              border: '1px solid #d9d9d9',
              borderRadius: '0.5rem',
              width: '100%',
              height: '3.7rem',
              padding: '1rem'
            }}
            countrySelectorStyleProps={{
              buttonStyle: {
                border: '1px solid #d9d9d9',
                borderRadius: '0.5rem',
                height: '3.7rem',
                padding: '1rem'
              }
            }}
            inputProps={{ id: 'cellphone' }}
            forceDialCode
          />
        </div>
      </SharedStyles.InputsContainer>

      <SharedStyles.InputsContainer>
        <Styles.VariableWidthContainer width="67%">
          <Input
            id="email"
            label="E-mail*"
            placeholder="Digite seu e-mail"
            type="email"
            value={email}
            errorMessage={getFieldError('email', errors)}
            onChange={(event) => changeFieldValue('email', event.target.value)}
          />
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="33%">
          <Input
            id="email-confirmation"
            label="Confirmação do e-mail*"
            placeholder="Confirme seu e-mail"
            type="email"
            value={emailConfirmation}
            errorMessage={getFieldError('emailConfirmation', errors)}
            onChange={(event) => changeFieldValue('emailConfirmation', event.target.value)}
          />
        </Styles.VariableWidthContainer>
      </SharedStyles.InputsContainer>

      <SharedStyles.InputsContainer>
        <Styles.VariableWidthContainer width="50%">
          <Input
            id="password"
            label="Senha*"
            placeholder="Digite sua senha"
            type="password"
            value={password}
            errorMessage={getFieldError('password', errors)}
            onChange={(event) => changeFieldValue('password', event.target.value)}
          />
          <Styles.PasswordGuidelines>
            A senha deve ter 8 caracteres com pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere
            especial
          </Styles.PasswordGuidelines>
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="50%">
          <Input
            id="password-confirmation"
            label="Confirmação da senha*"
            placeholder="Confirme sua senha"
            type="password"
            value={passwordConfirmation}
            errorMessage={getFieldError('passwordConfirmation', errors)}
            onChange={(event) => changeFieldValue('passwordConfirmation', event.target.value)}
          />
          <Styles.PasswordGuidelines>
            A senha deve ter 8 caracteres com pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere
            especial
          </Styles.PasswordGuidelines>
        </Styles.VariableWidthContainer>
      </SharedStyles.InputsContainer>

      {(typeOfBoard === 'CRM' || typeOfBoard === 'CRO' || typeOfBoard === 'RMS') && path === 'professional' && (
        <Select
          id="list-as-prescriber"
          label={
            <label htmlFor="list-as-prescriber">
              <p
                style={
                  isMobile ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }
                }>
                Você tem interesse em disponibilizar sua agenda para consultas na Cannect ?
                {/* <strong>
                   <a
                    href={`${window.location.origin}/profissionais-parceiros-cannabis-medicinal`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    médico prescritor
                  </a>
                </strong> */}
                <Tooltip
                  title="Vamos te informar como se tornar um profissional apto a receber agendamentos para consultas de pacientes que buscam nosso site"
                  trigger={isMobile ? 'click' : 'hover'}
                  placement="top"
                  style={{ zIndex: 1100 }}
                  overlayInnerStyle={{ fontWeight: 500, fontFamily: 'Poppins' }}>
                  <MdInfoOutline />
                </Tooltip>
              </p>
            </label>
          }
          options={LIST_AS_PRESCRIBER_POSSIBILITIES}
          value={listAsPrescriber === undefined ? undefined : listAsPrescriber ? 'true' : 'false'}
          errorMessage={getFieldError('listAsPrescriber', errors)}
          onChange={(newValue) => changeFieldValue('listAsPrescriber', newValue === 'true')}
        />
      )}

      <CheckboxInput
        checked={agreedToUseTerms}
        label={
          <p>
            Eu concordo com os termos e{' '}
            <Link to="politica-de-privacidade" target="_blank">
              condições e a política de privacidade
            </Link>
          </p>
        }
        hasError={!!getFieldError('agreedToUseTerms', errors)}
        onChange={changeAgreedToUseTerms}
        id="agreed-to-use-terms"
      />
      <CheckboxInput
        checked={receivePromotionalEmails}
        label="Quero receber e-mails promocionais e novidades sobre a Cannect"
        onChange={() => setReceivePromotionalEmails((state) => !state)}
        id="receive-promotional-emails"
      />

      <SharedStyles.ConfirmRegistrationContainer>
        <Button variant="outline" type="button" onClick={onGoBack}>
          Voltar
        </Button>
        <Button type="submit">Fazer inscrição</Button>
      </SharedStyles.ConfirmRegistrationContainer>
    </Styles.RegistrationFormContainer>
  )
}
