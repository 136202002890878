/* eslint-disable prefer-regex-literals */
import { validateCpf } from 'utils/validators'
import { z } from 'zod'

export const requiredFormSchema = z
  .object({
    path: z.string(),
    birthdate: z.string(),
    name: z.string().trim().min(5),
    nationality: z.string(),
    cpf: z.string(),
    rg: z.string(),
    rne: z.string(),
    genderIdentity: z.string(),
    cellphone: z.string().min(13),
    email: z.string().trim().email(),
    emailConfirmation: z.string().trim().email(),
    password: z
      .string()
      .regex(new RegExp('.*[A-Z].*'), 'One uppercase character')
      .regex(new RegExp('.*[a-z].*'), 'One lowercase character')
      .regex(new RegExp('.*\\d.*'), 'One number')
      .regex(new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'), 'One special character')
      .min(8, 'Too small')
      .max(16, 'Too big'),
    passwordConfirmation: z.string().min(1),
    listAsPrescriber: z.string(),
    agreedToUseTerms: z.boolean(),
    typeOfBoard: z.string()
  })
  .required()
  .refine((data) => data.agreedToUseTerms, {
    message: 'Field is required',
    path: ['agreedToUseTerms']
  })
  .refine(
    (data) => {
      if (data.path === 'patient') return true
      if (data.typeOfBoard !== 'CRM' && data.typeOfBoard !== 'CRO' && data.typeOfBoard !== 'RMS') return true
      return data.listAsPrescriber.length > 0
    },
    {
      message: 'Field is required',
      path: ['listAsPrescriber']
    }
  )
  .refine((data) => data.name.includes(' '), {
    message: 'Field is required',
    path: ['name']
  })
  .refine((data) => {
    if (data.path === 'patient') return true
    return (
      ((data.nationality === 'brasileira' || data.nationality === '') && data.rg.trim().length > 5) ||
        (data.nationality === 'estrangeira' && data.rne.trim().length > 5),
      {
        message: 'Field is required',
        path: ['rg', 'rne']
      }
    )
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'Passwords must be equals',
    path: ['passwordConfirmation']
  })
  .refine((data) => data.email === data.emailConfirmation, {
    message: 'Emails must be equals',
    path: ['emailConfirmation']
  })
  .refine((data) => validateCpf(data.cpf), {
    message: 'CPF is invalid',
    path: ['cpf']
  })
  .refine(
    (data) => {
      const splittedDate = data.birthdate?.split('/')
      if (splittedDate.length !== 3) return false
      if (splittedDate[2].length !== 4) return false

      const date = new Date(`${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`)
      const todayDate = new Date()
      if (date.getTime() > todayDate.getTime()) return false

      return true
    },
    {
      message: 'Invalid date',
      path: ['birthdate']
    }
  )
