import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const PersonalDataContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: #f8f8f8;
  padding: 2rem;
  border-radius: 0.5rem;

  height: fit-content;
`

export const InputsContainer = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

interface VariableWidthContainerProps {
  width: string
}
export const VariableWidthContainer = styled.div<VariableWidthContainerProps>`
  width: ${({ width }) => width};

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const InvisibleContainer = styled.div`
  height: 1px;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

export const ConfirmChangesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`

export const ChangeSensibleDataContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  max-width: 40%;

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
  }

  p > a {
    color: #3d3d3d;
  }

  @media ${MOBILE_WIDTH} {
    max-width: 100%;
    margin-bottom: 2rem;
  }
`
