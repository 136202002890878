import { zodResolver } from '@hookform/resolvers/zod'
import CustomInput from 'components/CustomInput'
import { useScheduling } from 'hooks/useScheduling'
import Button from 'new-components/Button'
import { SimplifiedIdentificationFormSchema } from 'pages/AppointmentScheduling/schedulingMock'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import { clearMask, dateMask } from 'utils/formatters'
import { PhoneInput } from 'react-international-phone'
import { NavigateButtonsWrapper } from '../../styles'
import 'react-international-phone/style.css'

export interface IdentificationFormProps {
  cpf: string
  birthday: string
  email: string
  name: string
  whatsapp: string
}

interface SimplifiedRegisterSuccess {
  success: boolean
  user: {
    uuid: string
    accepted_terms_of_use_at: any
    prescriber: boolean
    listed_prescriber: boolean
    integrated: boolean
    origin: string
    admission_date: any
    id: number
    name: string
    email: string
    phone: string
    registration_type: number
    cpf: string
    updatedAt: string
    createdAt: string
    refresh_token: string
  }
  message: string
}
export default function SimplifiedRegisterForm() {
  const { back, setLoading, nextCheckoutStep } = useScheduling()
  const [dateFormated, setDateFormated] = useState<string>()
  const { setValue: incluiValor } = useFormContext()
  const [cellphone, setCellphone] = useState('')
  const simplifiedRegisterForm = useForm<IdentificationFormProps>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: zodResolver(SimplifiedIdentificationFormSchema),
    defaultValues: {
      cpf: '',
      birthday: '',
      email: '',
      name: '',
      whatsapp: ''
    }
  })

  const { handleSubmit, reset, getValues, setValue } = simplifiedRegisterForm

  const handleBack = () => {
    reset()
    back()
  }

  const handleRegister: SubmitHandler<IdentificationFormProps> = async () => {
    try {
      setLoading(true)
      const { cpf, email, name, whatsapp } = getValues()

      const response = await api.post<SimplifiedRegisterSuccess>('/register-or-retrieve?generatePassword=true', {
        name,
        email,
        birthday: dateFormated,
        cpf: clearMask(cpf),
        nationality: 'brasileira',
        whatsapp: clearMask(whatsapp),
        registration_type: 1
      })

      const { user } = response.data

      await incluiValor('patient', user)

      nextCheckoutStep()
    } catch (err: any) {
      toast.error('Houve um erro ao realizar o cadastro. Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider {...simplifiedRegisterForm}>
      <form onSubmit={handleSubmit(handleRegister)}>
        <CustomInput outlined maxLength={14} type="cpf" name="cpf" label="CPF *" placeholder="CPF " />
        <CustomInput outlined name="name" label="Nome do paciente *" placeholder="Nome " />
        <CustomInput
          outlined
          type="date"
          onChange={(e) => {
            setDateFormated(dateMask(e.target.value))
          }}
          value={dateFormated}
          placeholder="dd/mm/aaaa"
          name="birthday"
          label="Data de nascimento *"
          maxLength={10}
        />
        <CustomInput outlined name="email" label="E-mail *" placeholder="E-mail" />
        <div
          style={{
            gap: '5px',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <span style={{ fontSize: '1rem', paddingLeft: '10px' }}>Telefone *</span>
          <PhoneInput
            defaultCountry="br"
            value={cellphone}
            onChange={(phone) => {
              setCellphone(phone.replace(/\D/g, ''))
              setValue('whatsapp', phone.replace(/\D/g, ''))
            }}
            inputStyle={{
              border: '1px solid #d9d9d9',
              borderRadius: '0.5rem',
              width: '100%',
              height: '3rem',
              padding: '1rem'
            }}
            countrySelectorStyleProps={{
              buttonStyle: {
                border: '1px solid #d9d9d9',
                borderRadius: '0.5rem',
                height: '3rem',
                padding: '1rem'
              }
            }}
            inputProps={{ id: 'whatsapp', name: 'whatsapp' }}
            forceDialCode
          />
        </div>
        <NavigateButtonsWrapper usage="identification">
          <Button type="button" isOutlined background="black" onClick={handleBack}>
            Voltar
          </Button>
          <Button type="submit" background="green">
            Salvar e continuar
          </Button>
        </NavigateButtonsWrapper>
      </form>
    </FormProvider>
  )
}
